import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Cta from "../components/cta"

const ProasisPage = () => (
    <Layout>
        <Seo 
            title="React.js development and machine learning case study for Healthtech software company" 
            description="Case study of the React.js frontend development and machine learning work done for Proasis, a health technology software provider in Australia"
        />

        <Hero
            h1="Web app and machine learning case study"  
            h2="Desert Scientific Software (Proasis)"
        />

        <section className="bg-purple section-pad-sm">
            <div className="container-lg">
                <div className="row">
                    <StaticImage 
                        src="../images/3d-molecule.jpg"
                        alt="Screens of the Proasis web application"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>
        </section>
       
        <section className="section-pad-sm" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Our relationship with Desert Scientific Software started out with Launch Lab rebuilding the frontend of their existing software called Proasis.</p>
                                    <p>Proasis4 helps medical research teams contribute to structure activity analysis, contribute to ligand design and to focus on evidence based approaches to drug discovery.</p>
                                    <p>Since then we continue to offer ongoing frontend development and support as well as Python development and Machine Learning development.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/react-developers-sydney-canberra/" className="btn btn-lg btn-line">Frontend development</Link>
                                    <Link to="/python-developer/" className="btn btn-lg btn-line">Python development</Link>
                                    <Link to="/machine-learning-developers-sydney/" className="btn btn-lg btn-line">Machine Learning</Link>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h4>The work</h4>
                                    <p>We re-developed the frontend of the Proasis4 web application using the popular React.js Javascript library. Technologies used include:</p>
                                    <ul>
                                        <li>React</li>
                                        <li>Redux</li>
                                    </ul>
                                    <p>We also made use of <a href="http://3dmol.csb.pitt.edu/">3DMol</a>, a Javascript library for visualising molecular data, and during development contributed code to this open source project.</p>
                                    <p>As our relationship with Proasis has grown, Launch Lab has also assisted this data rich company with machine learning development using Python and Google's Tensorflow.</p>
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>Proasis is not publicly available but you can find out more about the product on the DesertSci website below.</p>
                                    <a href="http://www.desertsci.com/products/proasis4/" className="btn btn-lg btn-purple">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            text="Get an obligation free software development, or machine learning, quote from an experienced local Australian web development team."
        />
    </Layout>
)

export default ProasisPage